export  const colors = {
    primary: "#1B164E",
    secondary: "#B19A19",
    accent: "#EDEDED",
    red: "#EA5354",
    yellow: "#FDBE01",
    white:"#fff3f3",
    darkBlue:"#26293C",
    lightBlue: "#ffffff",
    green:"#8cc34a",
    blue:"#1E89E5",
    deepyellow:"#FCCE05",
    aqua:"#01BCD4",
    body:"#26293C",
    card:"#2D334A",
    icon:"#CFD4EC",
    htext:"#7983BB",
    ctext:"#7366F0",
    lightgreen:"#264C62",
    lightblue:"#3A3B64",
    maingreen:"#00CFE7",
    lightred:"#4D394B",
    mainred:"#EA5354",
    lightsecondgreen:"#2F4A4E",
    mainsecondgreen:"#29C76F",
    text:"#9C9C9C",
    text2:"#AAAAAA",
    rcolor:"#002060",
    rblue:"#381BE9",
  
    primaryDark: '#2759ff',
    primaryLite: '#637aff99',
    black: '#000',
    white: 'white',
    accent: '#112233',
    green: '#60c5a8',
    green2: '#039a83',
    light: '#EEEEEE',
    dark: '#333',
    gray: '#CCCCCC',
    red: '#ff2f68',
    lightRed: '#ff4f7e',
    darkRed: '#d9365e',
    purple: '#8f06e4',
    skyBlue: 'skyblue',
    yellow: '#f8c907',
    pink: '#ff4c98',
    gold: 'gold',
    line: '#282C35',
  
    powderBlue: 'powderblue',
    skyBlue: 'skyblue',
    steelBlue: 'steelblue',
    green: '#60c5a8',
    red: '#E66155',
    black: '#112233',
    lightGreen: '#aaffaa',
    orange: '#FF886B',
    blue: '#0984FF',
    yellow: '#FEC10B',
    gray: '#A0AEC0',
    darkGray: '#2D3748',
    darkGray2: '#718096',
    white: 'white',
    light: '#fcfcfc',
  
    darkOverlayColor: 'rgba(0, 0, 0, 0.4)',
    darkOverlayColor2: 'rgba(0, 0, 0, 0.8)',
    lightOverlayColor: 'rgba(255, 255, 255, 0.6)',
    primaryAlpha: 'rgba(99, 122, 255, 0.15)',
    redAlpha: 'rgba(255, 84, 84, 0.15)',
    greenAlpha: 'rgba(96, 197, 168, 0.15)',
    purpleAlpha: 'rgba(146, 6, 228, 0.15)',
  
  
    // bags background colors
    bag1Bg: '#ea7a72',
    bag2Bg: '#c2c5d1',
    bag3Bg: '#82a7c9',
    bag4Bg: '#d49d8f',
    bag5Bg: '#ccd9c6',
    bag6Bg: '#767676',
    bag7Bg: '#d1c8c3',
    bag8Bg: '#dca47f',
    bag9Bg: '#eb849c',
    bag10Bg: '#979dc1',
    bag11Bg: '#c7d3c0',
  
  
  };
  