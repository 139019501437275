import React, { useEffect, useState } from 'react';
import "../Website/Website.css";
import Select from 'react-select';
import styled from 'styled-components';
import { AdmitButton3, AdmitStudentRole, FormInputStudent, FormLable } from '../../data/Profile';
import { colors } from '../../data/Colors';
import { contextMenuItems, continentList, countryList, customers, customersData, customersGrid, emailData, emailGrid, employeeData, employeeGrid, otherGrid, paymentData, paymentGrid, paymentMethod, paymentReference, products } from '../../data/champion';
import { GridComponent, ContextMenu, Edit, ExcelExport, Filter, Page, PdfExport, Resize, Sort, ColumnDirective, ColumnsDirective, Inject } from '@syncfusion/ej2-react-grids';
import { Header } from '../../components';
import Selector from '../../data/Selector';
import { Show } from '../../data/Alerts';
import { apiServer } from '../../data/Endpoint';
import { AES, enc } from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { Search, Toolbar } from '@syncfusion/ej2-react-grids';

const Admin = () => {
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      try {
        // Intentional empty block
      } catch (err) {
        if (err.message === 'ResizeObserver loop completed with undelivered notifications.') {
          console.warn('ResizeObserver loop error detected.');
        } else {
          throw err;
        }
      }
    });

    observer.observe(document.body); // Assuming observing the body for changes

    return () => observer.disconnect();
  }, []);

  const [CompanyName, setCompanyName] = useState("");

  const [Image1, setImage1] = useState("");
  const [Image2, setImage2] = useState("");
  const [Image3, setImage3] = useState("");
  const [Video1, setVideo1] = useState("");
  const [Whatsapp, setWhatsapp] = useState("");
  const [Instagram, setInstagram] = useState("");
  const [Facebook, setFacebook] = useState("");

  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    try {
      const encryptedData = sessionStorage.getItem("userDataEnc");
      const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
      const decryptedData = AES.decrypt(encryptedData, encryptionKey);
      const decryptedString = decryptedData.toString(enc.Utf8);
      const parsedData = JSON.parse(decryptedString);
      setUserInfo(parsedData);
    } catch (error) {
      navigate("/");
    }
  }, []);

  const handleFileChange = (setter) => (e) => {
    const file = e.target.files[0];
    const maxFileSize = 20 * 1024 * 1024; // 20MB in bytes
    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/x-icon'];
    const acceptedVideoTypes = ['video/mp4', 'video/webm', 'video/ogg'];

    if (file) {
      if (file.size > maxFileSize) {
        Show.Attention('File size exceeds 20MB.')
        e.target.value = null; // Clear the input
        return;
      }

      const isImage = acceptedImageTypes.includes(file.type);
      const isVideo = acceptedVideoTypes.includes(file.type);

      if (!isImage && !isVideo) {
        alert('Invalid file type. Please select a valid image or video file.');
        e.target.value = null; // Clear the input
        return;
      }

      setter(file);
    }
  };

  const handleCreateAdmin = async () => {
    Show.showLoading("Processing Data");
    try {
      const formData = new FormData();
      formData.append("CompanyName", CompanyName);
      formData.append("Image1", Image1);
      formData.append("Image2", Image2);
      formData.append("Image3", Image3);
      formData.append("Video", Video1);
      formData.append("Whatsapp", Whatsapp);
      formData.append("Instagram", Instagram);
      formData.append("Facebook", Facebook);
      formData.append("AdminId", userInfo.UserId);
  
      const response = await fetch(apiServer + "CreateWebsite", {
        method: "POST",
        headers: {
          'UserId': userInfo.UserId,
          'SessionId': userInfo.SessionId
        },
        body: formData
      });
  
      const data = await response.json();
  
      if (response.ok) {
        Show.hideLoading();
        Show.Success(data.message);
      } else {
        Show.Attention(data.message);
      }
    } catch (error) {
      Show.Attention("An error has occurred");
    }
  };
  

  return (
    <div>
      <Header category="System Operations" title="Website" />
      <div className="wwd-row">
        <div className="card" style={{ backgroundColor: localStorage.getItem("themeMode") === "Light" ? "#26293C" : "white" }}>
          <div className="sec-title" style={{ color: localStorage.getItem("colorMode"), padding: "2rem" }}>Configure Website </div>
          <AdmitStudentRole>
            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}>Company Name</FormLable>
              <FormInputStudent
                type="text"
                placeholder=""
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}>Image 1</FormLable>
              <FormInputStudent
                type="file"
                required
                placeholder=""
                accept=".jpg, .png, .jpeg, .ico, .webp"
                onChange={handleFileChange(setImage1)}
              />
            </div>
            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}>Image 2</FormLable>
              <FormInputStudent
                type="file"
                required
                placeholder=""
                accept=".jpg, .png, .jpeg, .ico, .webp"
                onChange={handleFileChange(setImage2)}
              />
            </div>
            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}>Image 3</FormLable>
              <FormInputStudent
                type="file"
                required
                placeholder=""
                accept=".jpg, .png, .jpeg, .ico, .webp"
                onChange={handleFileChange(setImage3)}
              />
            </div>
            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}>Video</FormLable>
              <FormInputStudent
                type="file"
                required
                placeholder=""
                accept="video/mp4, video/webm, video/ogg"
                onChange={(e)=>{setVideo1(e.target.files[0])}}
              />
            </div>
            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}>Whatsapp</FormLable>
              <FormInputStudent
                type="text"
                placeholder="eg: 233599626272"
                onChange={(e) => setWhatsapp(e.target.value)}
              />
            </div>
            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}>Instagram</FormLable>
              <FormInputStudent
                type="text"
                placeholder=""
                onChange={(e) => setInstagram(e.target.value)}
              />
            </div>
            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}>Facebook</FormLable>
              <FormInputStudent
                type="text"
                placeholder=""
                onChange={(e) => setFacebook(e.target.value)}
              />
            </div>
          </AdmitStudentRole>
          <AdmitButton3
            background={localStorage.getItem("colorMode")}
            color="white"
            border={localStorage.getItem("colorMode")}
            style={{ marginBottom: "1rem" }}
            onClick={handleCreateAdmin}
          >
            Add
          </AdmitButton3>
        </div>
      </div>
    </div>
  );
}

export default Admin;
